import React, { useRef, forwardRef, useEffect } from "react";
import { IconButton } from "@material-ui/core";

import MaterialTable from "material-table";

import axios from "config/axios";
import CustomAlert from "components/CustomAlert";

//import useStyles from "./styles";
import { AddBox, ArrowLeft, ArrowRight } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";

const Games: React.FC = () => {
  //const classes = useStyles();

  const history = useHistory();
  const params = useParams<{ placeId: string }>();

  const alertRef = useRef<any>();
  const tableRef = useRef<any>();

  const goToDetails = async (gameId: string) => {
    history.push(`/dashboard/game/${gameId}`);
  };

  useEffect(() => {
    if (!params.placeId) {
      return history.goBack();
    }
  }, [history, params.placeId]);

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <IconButton
        onClick={() => history.goBack()}
        color="secondary"
        size="medium"
        style={{ backgroundColor: "#333", marginBottom: 10 }}
      >
        <ArrowLeft />
      </IconButton>
      <MaterialTable
        options={{
          exportButton: true,
          grouping: false,
          search: false,
        }}
        style={{ fontFamily: "Nunito Sans" }}
        icons={{
          Add: forwardRef((props, ref) => (
            <AddBox color="primary" {...props} ref={ref} />
          )),
        }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        columns={[
          {
            title: "_id",
            field: "_id",
            type: "string",
            editable: "never",
            hidden: true,
          },
          {
            title: "Nome",
            field: "name",
            type: "string",
          },
          {
            title: "Início",
            field: "start",
            type: "datetime",
          },
          {
            title: "Fim",
            field: "end",
            type: "datetime",
          },
          {
            title: "Parado",
            field: "finished",
            type: "boolean",
          },
          {
            title: "Participantes",
            field: "countParticipants",
            editable: "never",
            type: "string",
          },
          {
            title: "",
            editable: "never",
            render: (data) => {
              return (
                <IconButton
                  onClick={() => goToDetails(data._id)}
                  color="secondary"
                  size="medium"
                  style={{ backgroundColor: "#333" }}
                >
                  <ArrowRight />
                </IconButton>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/admin/games/`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: params.placeId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    alertRef.current.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowUpdate: async (newData, oldData) => {
            try {
              await axios.put(`/admin/game`, {
                ...newData,
                _id: newData?._id,
              });

              alertRef.current.alterAlert("Atualizado com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao atualizar", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            addTooltip: "Adicionar usuário",
            deleteTooltip: "Remover jogo",
            editTooltip: "Editar jogo",
            editRow: {
              deleteText: "Remover jogo?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Jogos"
      />
    </div>
  );
};

export default Games;
