import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleContainer: {
      paddingLeft: 10,
      paddingBottom: 10,
    },
    tabsContainer: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);
export default useStyles;
