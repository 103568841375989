import React, { useState, useRef, useEffect } from "react";

import {
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  LinearProgress,
  useMediaQuery,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";

import useStyles from "./styles";
import axios from "config/axios";
import { setUserToken } from "functions/userToken";
import useMobile from "use-mobile-detect-hook";

import { useHistory, useParams } from "react-router-dom";

import CustomAlert from "components/CustomAlert";

import logo from "assets/img/patota_logo.svg";
import background from "assets/img/blob_back2.svg";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Entre um email válido")
    .required("O email é obrigatório"),
  password: yup.string().required("A senha é obrigatória"),
});

function Login() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const mobile = useMobile();

  const params = useParams<{ qrUuid?: string }>();

  const matches = useMediaQuery("(max-width:1280px)");

  const alertRef = useRef<any>();

  useEffect(() => {
    if (params?.qrUuid) {
      alertRef?.current.alterAlert(
        "Entre em sua conta ou crie uma conta para participar do jogo",
        "info"
      );
    }
  }, [params]);

  const login = async (values: { email: string; password: string }) => {
    setLoading(true);
    axios
      .post("/auth/admin/login", {
        email: values.email,
        password: values.password,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setUserToken(data.token);
        history.push("/dashboard/games");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Usuário não encontrado", "warning");
            break;
          case 401:
            alertRef?.current.alterAlert("Senha inválida", "warning");
            break;
          default:
            alertRef?.current.alterAlert("Problema ao entrar", "error");

            break;
        }
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(values);
    },
  });

  return (
    <div>
      {loading && <LinearProgress />}
      <CustomAlert ref={alertRef} />
      <img
        className={classes.background}
        src={background}
        alt="Imagem de fundo"
      />
      <div style={{ flex: 1, textAlign: "center" }}>
        {!mobile.isMobile() && (
          <img
            className={classes.logo}
            src={logo}
            alt="Logo picbit games"
            style={matches ? { marginLeft: "4vw" } : undefined}
          />
        )}
      </div>
      <div>
        <Card className={classes.leftSide} style={{ borderRadius: 0 }}>
          <CardContent className={classes.cardContent}>
            {mobile.isMobile() && (
              <div className={classes.logoMobileDiv}>
                <img
                  className={classes.logoMobile}
                  src={logo}
                  alt="Logo picbit games"
                />
              </div>
            )}
            <Typography variant="h4" className={classes.title}>
              Bem-vindo
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Senha"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={loading}
                style={{ marginTop: 15 }}
              >
                Entrar
              </Button>
            </form>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </div>
    </div>
  );
}

export default Login;
