import React, { forwardRef, useRef } from "react";
import axios from "config/axios";
import MaterialTable from "material-table";
import { AddBox } from "@material-ui/icons";
import { useParams } from "react-router";

interface Props {
  alertRef: React.MutableRefObject<any>;
}

const Users: React.FC<Props> = (props) => {
  const { alertRef } = props;

  const params = useParams<{ gameId: string }>();

  const tableRef = useRef<any>();

  return (
    <div>
      <MaterialTable
        options={{
          exportButton: true,
          grouping: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100, 200],
        }}
        style={{ fontFamily: "Nunito Sans" }}
        icons={{
          Add: forwardRef((props, ref) => (
            <AddBox color="primary" {...props} ref={ref} />
          )),
        }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        columns={[
          {
            title: "°",
            field: "position",
            type: "string",
            editable: "never",
          },
          {
            title: "_id",
            field: "_id",
            type: "string",
            editable: "never",
            hidden: true,
          },
          {
            title: "Nome",
            field: "user.name",
            type: "string",
            editable: "never",
          },
          {
            title: "Nick",
            field: "nickname",
            type: "string",
            render: (data) => {
              return data.nickname ? data.nickname : "-";
            },
          },
          {
            title: "Pontos",
            field: "gameValue",
            type: "numeric",
          },
          {
            title: "Cadastro",
            field: "createdAt",
            type: "date",
            editable: "never",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/admin/game/users`, {
                gameId: params.gameId,
                pageSize,
                orderBy,
                orderDirection,
                search,
                page,
              })
              .then(({ data }) => {
                data.docs.forEach((e: any, i: number) => {
                  e.position = `${i + 1}°`;
                });
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    alertRef.current.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowUpdate: async (newData, oldData) => {
            try {
              await axios.put(`/admin/game/user`, newData);
              alertRef.current.alterAlert("Atualizado com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao atualizar", "error");
                  break;
              }
            }
          },
          onRowDelete: async (oldData) => {
            try {
              await axios.delete(`/admin/game/user/${oldData._id}`);
              alertRef.current.alterAlert("Removido com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao remover", "error");
                  break;
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            addTooltip: "Adicionar usuário",
            deleteTooltip: "Remover usuário",
            editTooltip: "Editar usuário",
            editRow: {
              deleteText: "Remover usuário?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title=""
      />
    </div>
  );
};

export default Users;
