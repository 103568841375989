import { Routes } from "types";

import { Games as GamesIcon, LocationOn } from "@material-ui/icons";

import { Games, GameConfig, Places } from "views/dashboard";

export const adminRoutes: Routes = [
  {
    path: "/places",
    name: "Jogo",
    icon: LocationOn,
    component: Places,
    layout: "/dashboard",
    isVisible: true,
  },
  {
    path: "/games/:placeId",
    name: "Jogos",
    icon: GamesIcon,
    component: Games,
    layout: "/dashboard",
    isVisible: false,
  },
  {
    path: "/game/:gameId",
    name: "Jogo",
    icon: GamesIcon,
    component: GameConfig,
    layout: "/dashboard",
    isVisible: false,
  },
  /*  {
      path: "/list/deliveries",
    name: "Suas entregas",
      //rtlName: "لوحة القيادة",
      icon: "inventory_2",
      component: ListDeliveries,
      layout: "/admin",
    }, */
];
