import React, { useState, useRef } from "react";
import axios from "config/axios";
import {
  Grid,
  Paper,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useParams } from "react-router";
import download from "downloadjs";

interface Props {
  alertRef: React.MutableRefObject<any>;
}

const Awards: React.FC<Props> = (props) => {
  const [cooldown, setCooldown] = useState(""),
    [timeoutQr, setTimeoutQr] = useState(""),
    [amountQr, setAmountQr] = useState(""),
    [loading, setLoading] = useState(false);
  const { alertRef } = props;

  const tableRef = useRef<any>();

  const params = useParams<{ gameId: string }>();

  const updateCooldown = async () => {
    setLoading((oldState) => !oldState);
    axios
      .put("/admin/qr/cooldown", {
        cooldownTime: cooldown,
        gameId: params.gameId,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        alertRef.current.alterAlert("Atualizado com sucesso", "success");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error.response.data.code) {
          default:
            alertRef.current.alterAlert("Problema ao atualizar dados", "error");
            break;
        }
      });
  };

  const updateTimeout = async () => {
    setLoading((oldState) => !oldState);
    await axios
      .put(`/admin/game`, {
        _id: params.gameId,
        reactivateQRsTimer: timeoutQr,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        alertRef.current.alterAlert("Atualizado com sucesso", "success");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error.response.data.code) {
          default:
            alertRef.current.alterAlert("Problema ao atualizar dados", "error");
            break;
        }
      });
  };

  const createQrs = async () => {
    setLoading((oldState) => !oldState);
    await axios
      .post(
        `/admin/qr/create`,
        {
          game: params.gameId,
          amount: amountQr,
          color: "generic",
        },
        { responseType: "blob" }
      )
      .then((val) => {
        setLoading((oldState) => !oldState);
        alertRef.current.alterAlert("Criado com sucesso", "success");
        let { data, headers } = val;
        let contentType = headers["content-type"],
          split = contentType.split(";");
        download(
          data,
          `qrs-${params.gameId}-${new Date().getTime()}.pdf`,
          split[0]
        );
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error.response.data.code) {
          default:
            alertRef.current.alterAlert("Problema ao atualizar dados", "error");
            break;
        }
      });
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress size={45} />
      </div>
    );
  }

  return (
    <div>
      <Paper>
        <Grid container direction="column">
          <Grid item>
            <MaterialTable
              options={{
                exportButton: true,
                grouping: true,
                pageSize: 20,
                pageSizeOptions: [20, 50, 100, 200],
              }}
              style={{ fontFamily: "Nunito Sans" }}
              tableRef={tableRef}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Recarregar dados",
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef?.current?.onQueryChange(),
                },
              ]}
              columns={[
                {
                  title: "_id",
                  field: "_id",
                  type: "string",
                  editable: "never",
                  hidden: true,
                },
                {
                  title: "Nome",
                  field: "name",
                  type: "string",
                  render: (data) => {
                    return <p>{data.name ? data.name : "-"}</p>;
                  },
                },
                {
                  title: "UUID",
                  field: "uuid",
                  type: "string",
                  editable: "never",
                  render: (data) => {
                    return (
                      <p>{data.uuid ? data.uuid.substring(0, 4) : "-"}...</p>
                    );
                  },
                },
                {
                  title: "Cadastro",
                  field: "createdAt",
                  type: "date",
                  editable: "never",
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let { page, pageSize, orderBy, orderDirection, search } =
                    query;
                  axios
                    .post(`/admin/game/qrs`, {
                      gameId: params.gameId,
                      pageSize,
                      orderBy,
                      orderDirection,
                      search,
                      page,
                    })
                    .then(({ data }) => {
                      resolve({
                        data: data.docs,
                        page: data.page - 1,
                        totalCount: data.totalDocs,
                      });
                    })
                    .catch((error) => {
                      switch (error.response.data.code) {
                        default:
                          alertRef.current.alterAlert(
                            "Problema ao carregar dados",
                            "error"
                          );
                          break;
                      }
                      reject("Problema ao carregar dados");
                    });
                })
              }
              editable={{
                onRowUpdate: async (newData, oldData) => {
                  try {
                    await axios.put(`/admin/qr`, newData);
                    alertRef.current.alterAlert(
                      "Atualizado com sucesso",
                      "success"
                    );
                  } catch (error: any) {
                    console.log(error);
                    switch (error?.response?.data?.status) {
                      case 404:
                        alertRef.current.alterAlert(
                          "Usuário não encontrado",
                          "warning"
                        );
                        break;
                      default:
                        alertRef.current.alterAlert(
                          "Problema ao atualizar",
                          "error"
                        );
                        break;
                    }
                  }
                },
                onRowDelete: async (oldData) => {
                  try {
                    await axios.delete(`/admin/game/qr/${oldData._id}`);
                    alertRef.current.alterAlert(
                      "Removido com sucesso",
                      "success"
                    );
                  } catch (error: any) {
                    console.log(error);
                    switch (error?.response?.data?.status) {
                      case 404:
                        alertRef.current.alterAlert(
                          "Usuário não encontrado",
                          "warning"
                        );
                        break;
                      default:
                        alertRef.current.alterAlert(
                          "Problema ao remover",
                          "error"
                        );
                        break;
                    }
                  }
                },
              }}
              localization={{
                header: {
                  actions: "Ações",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "registros",
                  labelRowsPerPage: "Registros por página:",
                  firstAriaLabel: "Primeira",
                  firstTooltip: "Primeira",
                  previousAriaLabel: "Anterior",
                  previousTooltip: "Anterior",
                  nextAriaLabel: "Próxima",
                  nextTooltip: "Próxima",
                  lastAriaLabel: "Última",
                  lastTooltip: "Última",
                },
                toolbar: {
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  exportCSVName: "Exportar CSV",
                  exportPDFName: "Exportar PDF",
                  searchTooltip: "Pesquisar",
                  searchPlaceholder: "Pesquisar",
                },
                body: {
                  emptyDataSourceMessage: "Sem informações",
                  addTooltip: "Adicionar qrs",
                  deleteTooltip: "Remover qr",
                  editTooltip: "Editar qr",
                  editRow: {
                    deleteText: "Remover qr?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Confirmar",
                  },
                },
                grouping: {
                  groupedBy: "Agrupado por:",
                  placeholder: "Arraste um cabeçalho para agrupar",
                },
              }}
              title=""
            />
          </Grid>
          <Grid item style={{ padding: 20 }}>
            <Typography variant="h6">Cooldown</Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
              spacing={2}
            >
              {/*  <Grid item>
            <Typography>Cooldown</Typography>
          </Grid> */}
              <Grid item>
                <TextField
                  fullWidth
                  id="value"
                  name="value"
                  type="numeric"
                  label="Novo valor do cooldown (minutos)"
                  value={cooldown}
                  onChange={(e) => setCooldown(e.target.value)}
                  style={{ marginTop: "5%" }}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={loading}
                  onClick={updateCooldown}
                  style={{ marginTop: 15 }}
                >
                  Atualizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: 20, marginTop: 10 }}>
            <Typography variant="h6">Leitura do mesmo qr</Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TextField
                  fullWidth
                  id="value"
                  name="value"
                  type="numeric"
                  label="Novo valor (minutos)"
                  value={timeoutQr}
                  onChange={(e) => setTimeoutQr(e.target.value)}
                  style={{ marginTop: "5%" }}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={loading}
                  onClick={updateTimeout}
                  style={{ marginTop: 15 }}
                >
                  Atualizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: 20, marginTop: 10 }}>
            <Typography variant="h6">Geração de QR Codes</Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TextField
                  fullWidth
                  id="value"
                  name="value"
                  type="numeric"
                  label="Quantidade"
                  value={amountQr}
                  onChange={(e) => setAmountQr(e.target.value)}
                  style={{ marginTop: "5%" }}
                  inputProps={{ style: { padding: 13 } }}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={loading}
                  onClick={createQrs}
                  style={{ marginTop: 15 }}
                >
                  Criar e baixar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Awards;
