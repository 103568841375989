import React, { forwardRef, useRef } from "react";
import axios from "config/axios";
import MaterialTable from "material-table";
import { AddBox, Camera } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useParams } from "react-router";

interface Props {
  alertRef: React.MutableRefObject<any>;
}

const Hints: React.FC<Props> = (props) => {
  const { alertRef } = props;
  const params = useParams<{ gameId: string }>();

  const tableRef = useRef<any>();
  const hiddenFileInput = useRef<any>(null);

  const onFileUpload = (data: { _id: string; value: string }) => {
    if (!data.value) return;
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", data.value);

    formData.append("_id", data._id);
    // Details of the uploaded file
    console.log(data.value);

    // Request made to the backend api
    // Send formData object
    return axios.post("/admin/hint/uploadPhoto", formData);
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <div>
      <MaterialTable
        options={{
          exportButton: true,
          grouping: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100, 200],
        }}
        style={{ fontFamily: "Nunito Sans" }}
        icons={{
          Add: forwardRef((props, ref) => (
            <AddBox color="primary" {...props} ref={ref} />
          )),
        }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        columns={[
          {
            title: "_id",
            field: "_id",
            type: "string",
            editable: "never",
            hidden: true,
          },
          {
            title: "Dica",
            field: "hint",
            type: "string",
          },
          {
            title: "Imagem",
            field: "s3key",
            type: "string",
            editComponent: (data) => {
              return (
                <>
                  <input
                    type="file"
                    accept="image/png"
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      if (e.target.files) {
                        console.log(e.target.files);
                        data.onChange(e?.target?.files[0]);
                      }
                    }}
                    style={{ display: "none" }}
                  />
                  <IconButton
                    onClick={handleClick}
                    color="secondary"
                    size="medium"
                    style={{ backgroundColor: "#333" }}
                  >
                    <Camera />
                  </IconButton>
                </>
              );
            },
            render: (data) => {
              return (
                <div>
                  <p>{data.s3key?.substring(0, 5)}...</p>
                </div>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/admin/game/hints`, {
                gameId: params.gameId,
                pageSize,
                orderBy,
                orderDirection,
                search,
                page,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    alertRef.current.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        editable={{
          onRowUpdate: async (newData, oldData) => {
            try {
              if (typeof newData.s3key === "object") {
                await onFileUpload({ _id: newData._id, value: newData.s3key });
              }
              delete newData.s3key;
              await axios.put(`/admin/game/hint`, newData);
              alertRef.current.alterAlert("Atualizado com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao atualizar", "error");
                  break;
              }
            }
          },
          onRowAdd: async (newData) => {
            try {
              const value = newData.s3key;
              if (!value) {
                return alertRef.current.alterAlert(
                  "Selecione uma foto",
                  "warning"
                );
              }
              delete newData.s3key;
              const { data } = await axios.post(`/admin/hint/create`, {
                ...newData,
                game: params.gameId,
              });
              if (typeof value === "object") {
                await onFileUpload({ _id: data._id, value });
              }
              alertRef.current.alterAlert("Adicionado com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao adicionar", "error");
                  break;
              }
            }
          },
          onRowDelete: async (oldData) => {
            try {
              await axios.delete(`/admin/game/hint/${oldData._id}`);
              alertRef.current.alterAlert("Excluido com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao atualizar", "error");
                  break;
              }
            }
          },
          /* onRowAdd: async (newData) => {
            try {
              await axios.post(`/admin/game/award`, {
                ...newData,
                game: params.gameId,
              });

              alertRef.current.alterAlert("Atualizado com sucesso", "success");
            } catch (error: any) {
              console.log(error);
              switch (error?.response?.data?.status) {
                case 404:
                  alertRef.current.alterAlert(
                    "Usuário não encontrado",
                    "warning"
                  );
                  break;
                default:
                  alertRef.current.alterAlert("Problema ao atualizar", "error");
                  break;
              }
            }
          }, */
        }}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
            addTooltip: "Adicionar dica",
            deleteTooltip: "Remover dica",
            editTooltip: "Editar dica",
            editRow: {
              deleteText: "Remover dica?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Confirmar",
            },
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title=""
      />
    </div>
  );
};

export default Hints;
