import React, { useState, useRef } from "react";
import {
  IconButton,
  Paper,
  Typography,
  Grid,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";

import TabPanel from "components/TabPanel";

import CustomAlert from "components/CustomAlert";

import useStyles from "./styles";
import { ArrowLeftSharp } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useTheme } from "@material-ui/core/styles";
import Awards from "components/GameTabs/Awards";
import AwardsDelivered from "components/GameTabs/AwardsDelivered";
import Users from "components/GameTabs/Users";
import Qrs from "components/GameTabs/Qrs";
import Hints from "components/GameTabs/Hints";

const GameConfig: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const classes = useStyles();

  const history = useHistory();

  const alertRef = useRef<any>();

  const theme = useTheme();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const renderTabs = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          className={classes.titleContainer}
        >
          <Grid item>
            <IconButton size="medium" onClick={() => history.goBack()}>
              <ArrowLeftSharp fontSize={"large"} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6">Voltar</Typography>
          </Grid>
        </Grid>
        <div className={classes.tabsContainer}>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Prêmios" />
              <Tab label="Saída de prêmios" />
              <Tab label="Dicas" />
              <Tab label="Usuários" />
              <Tab label="QRS" />
            </Tabs>
          </AppBar>
          <div>
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>
              <Awards alertRef={alertRef} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
              <AwardsDelivered alertRef={alertRef} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2} dir={theme.direction}>
              <Hints alertRef={alertRef} />
            </TabPanel>
            <TabPanel value={selectedTab} index={3} dir={theme.direction}>
              <Users alertRef={alertRef} />
            </TabPanel>
            <TabPanel value={selectedTab} index={4} dir={theme.direction}>
              <Qrs alertRef={alertRef} />
            </TabPanel>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <Paper>{renderTabs()}</Paper>
    </div>
  );
};

export default GameConfig;
